import { VillageStep1Data } from './VillageStep1';
import { ChildParticipationControllerService } from '@/__generated__/FrontApi';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import FormTextTip from '@/components/Form/FormTextTip';
import LabelText from '@/components/Form/LabelText';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import AddressButton from '@/components/Input/AddressButton';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputText from '@/components/Input/InputText';
import MultiSelect, { Option } from '@/components/Multiselect/MultiSelect';
import Select from '@/components/Select';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { mobileRegex, telRegex } from '@/helpers/ValidationHelper';
import { FormButton, RightCol } from '@/page-blocks/auth/AuthCommon';
import { usePopupStore } from '@/stores/PopupStore';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';
import moment from 'moment';

const primarySchoolOptions: Option[] = [
  { value: 1, label: '1학년' },
  { value: 2, label: '2학년' },
  { value: 3, label: '3학년' },
  { value: 4, label: '4학년' },
  { value: 5, label: '5학년' },
  { value: 6, label: '6학년' },
];

const middleHighSchoolOptions: Option[] = [
  { value: 1, label: '1학년' },
  { value: 2, label: '2학년' },
  { value: 3, label: '3학년' },
];

const StepHead = styled.div`
  line-height: 1.2;
  padding: 29px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  &.agree-head {
    margin-top: 40px;
    border-top: 1px solid #e5e6e8;
    border-bottom: 1px solid #b7b8ba;
    padding-bottom: 16px;
  }
`;

const StepContainer = styled.div`
  .time-flex {
    margin-top: 92px;
    & > li {
      width: 50%;
      padding: 0 16px;

      button {
        width: 100%;
      }
    }
  }

  .btn-wrap {
    margin-top: 126px;
    text-align: center;

    button {
      width: 100%;
      max-width: 256px;
    }
  }

  .form-container {
    .col-flex {
      .col-left {
        width: 50%;
      }
      .col-right {
        width: 50%;
        height: 56px;
      }
    }

    ${breakpoint(`tablet`)} {
      .col-flex {
        .col-left {
          width: 100%;
        }
        .col-right {
          width: 100%;
        }
      }
    }

    .FormGroup {
      &.f1 {
        padding-bottom: 40px;
        border-bottom: 1px solid #e5e6e8;

        ${breakpoint(`mobile`)} {
          .col-flex {
            .col-left,
            .col-right {
              width: 100%;
            }
            .col-right {
              margin-top: 8px;
            }
          }
        }
      }

      .tip {
        margin-top: 8px;
        font-size: 14px;
        ${breakpoint(640)} {
          font-size: 12px;
        }
      }
    }

    .btn-down {
      width: 100%;
      max-width: 320px;
      margin-top: 16px;
    }

    ${breakpoint(`mobile`)} {
      .btn-down {
        max-width: 100%;
      }
    }
    .twinIn {
      .col-flex {
        .col-left {
          margin-bottom: 8px;
        }
        .col-right {
          margin-bottom: 8px;
        }
      }
    }
  }
`;

const AgreeTable = styled.div`
  margin: 8px 0;
  table {
    margin-top: 0;
    border-top: 2px solid #2d2926;
    th {
      font-weight: bold;
      text-align: center;
    }
    td {
      text-align: center;
    }
    th,
    td {
      height: auto;
      padding: 12px 4px;
      font-size: 16px;
      line-height: normal;
      border-top: none;
    }
    td {
      border-bottom: 1px solid #ddd;
    }
  }
  ${breakpoint(640)} {
    table {
      th,
      td {
        font-size: 14px;
      }
    }
  }
`;

export interface VillageStep2Data {
  accompanyCnt: string;
  agency: string;
  agencyAddrBasic: string;
  agencyAddrDetail?: string;
  agencyAddrZip: string;
  agencyTel: string;
  applyUserEmail: string;
  applyUserName: string;
  applyUserPhone: string;
  contents?: string;
  endTime: string;
  isAgreeYn1: boolean;
  isAgreeYn2: boolean;
  organGrade?: string;
  organType: string;
  personnelCount: string;
  selectDate: string;
  selectTime: string;
  startTime: string;
}

interface VillageStep2Props {
  onSubmit: (data: VillageStep2Data) => void;
  step1Data?: VillageStep1Data;
  queryParams: any;
  data: any;
}

const VillageStep2: FC<VillageStep2Props> = ({
  onSubmit,
  step1Data,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectDate = step1Data?.userSelectDate;
  const selectTime = step1Data?.userTimeType;
  const startTime = step1Data?.startTime;
  const endTime = step1Data?.endTime;

  const popupStore = usePopupStore();

  const {
    handleSubmit,
    setValue,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<VillageStep2Data>({
    defaultValues: {
      selectDate,
      selectTime,
      startTime,
      endTime,
      agency: '',
      agencyAddrBasic: '',
      agencyAddrDetail: '',
      agencyAddrZip: '',
      agencyTel: '',
      applyUserEmail: '',
      applyUserName: '',
      applyUserPhone: '',
      contents: '',
      isAgreeYn1: false,
      isAgreeYn2: false,
      organGrade: '',
      organType: '',
      personnelCount: '',
      accompanyCnt: '',
    },
  });

  const [optionSelected, setSelected] = useState<Option[] | null>(null);

  const organType = watch('organType');
  useEffect(() => {
    setSelected(null);
    setValue('organGrade', '');
  }, [organType, setValue]);

  const handleGradeChange = (selected: Option[]) => {
    setSelected(selected);
    setValue('organGrade', selected.map((opt) => opt.value).join(','));
  };

  const currentChild = (data: number) => {
    props.currentParent(data);
  };

  const onSubmitForm: SubmitHandler<VillageStep2Data> = useCallback(
    async (data) => {
      setIsSubmitting(true);

      try {
        const {
          resultCode,
          resultMessage,
        } = await ChildParticipationControllerService.childApplyInsertUsingPost(
          {
            accompanyCnt: data.accompanyCnt,
            agency: data.agency,
            applyAttGrpNo: null,
            agencyAddrBasic: data.agencyAddrBasic,
            agencyAddrDetail: data.agencyAddrDetail,
            agencyAddrZip: data.agencyAddrZip,
            agencyTel: data.agencyTel.replace(/-/g, ''),
            applyUserEmail: data.applyUserEmail,
            applyUserName: data.applyUserName,
            applyUserPhone: data.applyUserPhone.replace(/-/g, ''),
            contents: data.contents,
            endTime: endTime,
            isAgreeYn1: data.isAgreeYn1 ? 'Y' : 'N',
            isAgreeYn2: data.isAgreeYn2 ? 'Y' : 'N',
            organGrade: watch('organGrade'),
            organType: data.organType,
            selectDate: data.selectDate,
            selectTime: data.selectTime,
            startTime: startTime,
            personnelCount: data.personnelCount,
          },
        );

        if (resultCode === 'success') {
          onSubmit(data);
          reset();
        } else {
          const message =
            resultMessage ||
            '유니세프 어린이 체험관 신청에 실패했습니다. 다시 시도해주세요.';
          popupStore.show(message);
        }
      } catch (e: any) {
        console.error(e);
        const resultMessage =
          e?.response?.data?.resultMessage ||
          '유니세프 어린이 체험관 신청에 실패했습니다. 다시 시도해주세요.';
        popupStore.show(resultMessage);
      } finally {
        setIsSubmitting(false); // 추가: 요청 완료 후 버튼 활성화
      }
    },
    [popupStore, reset, onSubmit],
  );

  return (
    <StepContainer>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="form-container">
          <FormGroup className="FormGroup f1">
            <LabelText require>체험일시</LabelText>
            <div className="col-flex flex">
              <div className="col-left">
                <InputText
                  placeholder=""
                  name="selectDate"
                  ref={register}
                  readOnly
                  value={step1Data?.userSelectDate}
                />
              </div>
              <div className="col-right">
                <input
                  type="hidden"
                  name="selectTime"
                  value={step1Data?.userTimeType}
                  ref={register}
                />
                <InputText
                  placeholder=""
                  name="selectTimeVisible"
                  readOnly
                  value={`${moment(startTime, 'HHmm').format('HH:mm')} ~`}
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>기관명</LabelText>
            <InputText
              placeholder="기관명 입력"
              name="agency"
              ref={register({ required: '기관명을 입력해주세요.' })}
            />
            {errors.agency && (
              <ErrorMessage>{errors.agency.message}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>기관주소</LabelText>
            <Row>
              <Col desktop="auto">
                <InputText
                  name="agencyAddrZip"
                  ref={register({
                    required: {
                      value: true,
                      message: `기관주소를 입력해주세요.`,
                    },
                  })}
                  readOnly
                />
              </Col>
              <RightCol desktop="none">
                <AddressButton
                  onSelectAddress={(address) => {
                    setValue('agencyAddrZip', address.zonecode);
                    setValue('agencyAddrBasic', address.address);
                  }}
                  renderButton={(buttonProps) => (
                    <FormButton
                      {...buttonProps}
                      outline
                      css={`
                        ${breakpoint(768)} {
                          margin-top: 0 !important;
                        }
                      `}
                    >
                      주소검색
                    </FormButton>
                  )}
                />
              </RightCol>
            </Row>
            <Row>
              <InputText name="agencyAddrBasic" ref={register} readOnly />
            </Row>
            <Row>
              <InputText
                name="agencyAddrDetail"
                ref={register}
                placeholder="상세주소 입력 (선택입력 가능)"
              />
            </Row>
            {errors.agencyAddrZip && (
              <ErrorMessage>{errors.agencyAddrZip.message}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>기관연락처</LabelText>
            <InputText
              placeholder="기관연락처 입력 ( '-' 제외 )"
              name="agencyTel"
              ref={register({
                required: '기관연락처를 입력해주세요.',
                pattern: {
                  value: telRegex,
                  message: '올바른 기관연락처가 아닙니다.',
                },
                validate: {
                  isValidNumber: (value) => {
                    const isTel = telRegex.test(value);
                    if (!isTel) {
                      return '올바른 기관연락처가 아닙니다.';
                    }

                    return true;
                  },
                },
              })}
            />
            {errors.agencyTel && (
              <ErrorMessage>{errors.agencyTel.message}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup className="FormGroup twinIn">
            <LabelText require>신청인원 및 구분</LabelText>
            <div className="col-flex flex">
              <div className="col-left">
                <Select
                  name="organType"
                  ref={register({ required: '구분을 선택해주세요.' })}
                  defaultValue=""
                  onChange={(e) => setValue('organType', e.target.value)}
                >
                  <option value="" disabled>
                    구분 선택
                  </option>
                  <option value="어린이집">어린이집</option>
                  <option value="유치원">유치원</option>
                  <option value="초등">초등</option>
                  <option value="중등">중등</option>
                  <option value="고등">고등</option>
                  <option value="기타단체">기타단체</option>
                </Select>
              </div>
              {['초등', '중등', '고등'].includes(organType) && (
                <div className="col-right">
                  <MultiSelect
                    key="example_id"
                    options={
                      organType === '초등'
                        ? primarySchoolOptions
                        : middleHighSchoolOptions
                    }
                    onChange={handleGradeChange}
                    value={optionSelected}
                    isSelectAll
                    menuPlacement="bottom"
                    width="100%"
                    height="56px"
                    placeholderText="학년"
                  />
                  <input
                    type="hidden"
                    name="organGrade"
                    ref={register({
                      validate: (value) => {
                        if (
                          ['초등', '중등', '고등'].includes(organType) &&
                          !value
                        ) {
                          return '학년을 선택해주세요.';
                        }
                        return true;
                      },
                    })}
                    value={optionSelected?.map((opt) => opt.value).join(',')}
                  />
                </div>
              )}
            </div>
            <div className="col-flex flex">
              <div className="col-left">
                <Select
                  name="personnelCount"
                  ref={register({
                    required: '참석 아동 인원 수를 선택해주세요.',
                  })}
                  defaultValue=""
                  onChange={(e) => setValue('personnelCount', e.target.value)}
                  placeholder="참석 아동 인원 수"
                >
                  <option value="" disabled>
                    참석 아동 인원 수
                  </option>
                  {[...Array(30)].map((_, i) => {
                    return (
                      i > 2 && (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      )
                    );
                  })}
                </Select>
              </div>
              <div className="col-right">
                <Select
                  name="accompanyCnt"
                  ref={register({
                    required: '동행성인 보호자 인원 수를 선택해주세요.',
                  })}
                  defaultValue=""
                  onChange={(e) => setValue('accompanyCnt', e.target.value)}
                >
                  <option value="" disabled>
                    동행성인 보호자 인원수
                  </option>
                  {[...Array(11)].map((_, i, { length }) => {
                    const value = i + 1 === length ? `${i + 1}인 이상` : i + 1;
                    return (
                      <option key={i + 1} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </Select>
              </div>
            </div>
            {errors.organType && (
              <ErrorMessage>{errors.organType.message}</ErrorMessage>
            )}
            {errors.organGrade && (
              <ErrorMessage>{errors.organGrade.message}</ErrorMessage>
            )}
            {errors.accompanyCnt && (
              <ErrorMessage>{errors.accompanyCnt.message}</ErrorMessage>
            )}
            {errors.personnelCount && (
              <ErrorMessage>{errors.personnelCount.message}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText>기타 요청사항</LabelText>
            <InputText
              placeholder="기타 요청사항 입력(선택)"
              name="contents"
              ref={register}
              defaultValue=""
            />
            <p className="tip">
              (동행하는 성인 보호자 인원이 11인 이상인 경우, 정확한 인원을
              기재해주세요)
            </p>
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>신청자명</LabelText>
            <InputText
              placeholder="신청자명 입력"
              name="applyUserName"
              ref={register({ required: '신청자명을 입력해주세요.' })}
            />
            {errors.applyUserName && (
              <ErrorMessage>{errors.applyUserName.message}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>휴대폰번호</LabelText>
            <InputText
              placeholder="휴대폰번호 입력 ( '-' 제외 )"
              name="applyUserPhone"
              ref={register({
                required: '휴대폰번호를 입력해주세요.',
                pattern: {
                  value: mobileRegex,
                  message: '올바른 휴대폰번호가 아닙니다.',
                },
                validate: {
                  isValidRange: (value) =>
                    (value.substr(0, 3) === '010' && value.length === 11) ||
                    (value.substr(0, 3) !== '010' && value.length === 10) ||
                    '휴대폰번호 자릿수를 확인해주세요',
                },
              })}
            />
            {errors.applyUserPhone && (
              <ErrorMessage>{errors.applyUserPhone.message}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>이메일</LabelText>
            <InputText
              placeholder="이메일주소 입력"
              name="applyUserEmail"
              ref={register({
                required: '이메일주소를 입력해주세요.',
                pattern: {
                  value: /^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}$/,
                  message: '올바른 이메일 형식이 아닙니다.',
                },
              })}
            />
            {errors.applyUserEmail && (
              <ErrorMessage>{errors.applyUserEmail.message}</ErrorMessage>
            )}
          </FormGroup>

          <StepHead>
            <h2>
              <Tit size="s3">개인정보 수집 ∙ 이용 동의</Tit>
            </h2>
          </StepHead>

          <FormGroup className="chk-group full">
            <InputCheckbox
              label="[필수] 개인정보 수집 ∙ 이용 동의"
              name="isAgreeYn1"
              ref={register({
                required: '개인정보 수집 ∙ 이용 동의에 체크해주세요.',
              })}
            />
            <AgreeTable>
              <table>
                <tbody>
                  <tr>
                    <th>목적</th>
                    <th>항목</th>
                    <th>보유기간</th>
                  </tr>
                  <tr>
                    <td>참가신청 확인 및 안내</td>
                    <td>
                      기관명, 기관주소, 기관 연락처, 학년, 신청자명, 신청자
                      연락처, 신청자 이메일
                    </td>
                    <td className="ellipsis">행사 종료 후 3개월 이내 파기</td>
                  </tr>
                </tbody>
              </table>
            </AgreeTable>
            {errors.isAgreeYn1 && (
              <ErrorMessage>{errors.isAgreeYn1.message}</ErrorMessage>
            )}
            <p className="tip">
              <FormTextTip>
                위의 개인정보 수집 ∙ 이용에 동의하지 않을 경우 참가신청이
                제한됩니다.
              </FormTextTip>
            </p>
          </FormGroup>

          <FormGroup className="chk-group full">
            <InputCheckbox
              label="[선택] 개인정보 수집 ∙ 이용 동의"
              name="isAgreeYn2"
              ref={register}
            />
            <AgreeTable>
              <table>
                <tr>
                  <th>목적</th>
                  <th>항목</th>
                  <th>보유기간</th>
                </tr>
                <tr>
                  <td>아동권리 교육 및 행사 안내</td>
                  <td>
                    기관명, 기관주소, 기관 연락처, 학년, 신청자명, 신청자
                    연락처, 신청자 이메일
                  </td>
                  <td className="ellipsis">2년</td>
                </tr>
              </table>
            </AgreeTable>
            <p className="tip">
              <FormTextTip>
                위의 개인정보 수집 ∙ 이용에 동의하지 않더라도 참가신청이
                가능합니다.
              </FormTextTip>
            </p>
          </FormGroup>
        </div>
        <ul className="time-flex flex">
          <li>
            <Button outline size="sm" onClick={() => currentChild(1)}>
              날짜 재선택
            </Button>
          </li>
          <li>
            <Button size="sm" type="submit" disabled={isSubmitting}>
              {isSubmitting ? '신청 중...' : '신청하기'}
            </Button>
          </li>
        </ul>
      </form>
    </StepContainer>
  );
};
export default VillageStep2;
