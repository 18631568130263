import { ChildParticipationControllerService } from '@/__generated__/FrontApi';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import InputRadio, { RadioTabButton } from '@/components/Input/InputRadio';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { PageProps } from 'gatsby';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import 'moment/locale/ko';
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';
import { SubmitHandler, useForm } from 'react-hook-form';
import 'react-dates/lib/css/_datepicker.css';
import styled from 'styled-components';
import prevMonth from '@/assets/img/ico_pagination_prev@2x.png';
import nextMonth from '@/assets/img/ico_pagination_next@2x.png';

const SelectedContainer = styled.div`
  padding: 32px 17px;
  border-top: 1px solid #e5e6e8;
  border-bottom: 1px solid #e5e6e8;
  ${Tit} {
    line-height: 1.6;
  }
  ${breakpoint(640)} {
    padding: 24px 0;
  }
`;

const StepContainer = styled.div`
  .time-flex {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;

    & > li {
      width: 50%;
      margin-bottom: 32px;
      input[type='radio'][readonly]
        + ${RadioTabButton},
        input[type='radio']:disabled
        + ${RadioTabButton} {
        &:hover {
          background: #fff;
          cursor: default;
        }
      }
      label {
        min-width: 94.1%;
      }
      :nth-child(even) label {
        float: right;
      }
      :nth-last-child(1),
      :nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }
  .tip {
    &:first-of-type {
      margin-top: 20px;
    }
    color: #afb0b3;
    font-size: 16px;
    padding-left: 20px;
    position: relative;
    ${breakpoint(640)} {
      font-size: 14px;
    }
    ::before {
      content: '※ ';
      position: absolute;
      left: 0;
      top: 0;
      font-size: 16px;
      color: #b0b0b0;
    }
  }
  .btn-wrap {
    margin-top: 126px;
    text-align: center;

    button {
      width: 100%;
      max-width: 256px;
    }
  }
  ${breakpoint(640)} {
    .btn-wrap {
      margin-top: 72px;
    }
  }
`;

const DayPickerWrap = styled.div`
  width: 100%;
  position: relative;
  div {
    max-width: 100%;
  }
  .CalendarMonth_caption {
    padding-top: 15px;
    padding-bottom: 15px;
    color: #2d2926;
    font-size: 18px;
  }
  .DayPicker__hidden {
    visibility: visible;
  }
  .DayPickerNavigation_button__default {
    border: none;
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    width: 24px;
    height: 24px;
    background: url(${prevMonth}) no-repeat center center;
    background-size: 24px;
    svg {
      display: none;
    }
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    width: 24px;
    height: 24px;
    background: url(${nextMonth}) no-repeat center center;
    background-size: 24px;
    svg {
      display: none;
    }
  }

  .CalendarMonth {
    padding: 0 !important;
  }
  .DayPicker_weekHeader {
    padding: 0 !important;
    width: 100%;
    ${breakpoint(640)} {
    }
  }
  .DayPicker_weekHeader_ul {
    width: 100%;
    .DayPicker_weekHeader_li {
      width: 14.2% !important;
      font-weight: bold;
      font-size: 18px;
      color: #2d2926;
      &:first-child {
        color: #e2231a;
      }
      &:last-child {
        color: #1cabe2;
      }
    }
    ${breakpoint(640)} {
    }
  }
  .DayPicker .DayPicker_transitionContainer__horizontal {
    transition: none;
    box-sizing: content-box;
    min-height: 484px;
    padding-bottom: 38px;
    table {
      border: none;
    }
    th,
    td {
      width: 76px;
      height: 75px;
      border: none;
      padding: 0;
      color: #b7b8ba;
      text-align: center;
      &:hover {
        background-color: transparent;
      }
      ${breakpoint(640)} {
        width: 14.2% !important;
      }
      div {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: block;
          position: relative;
          z-index: 1;
          &::after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: #b7b8ba;
            margin: 0 auto;
          }
        }
      }
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: transparent;
      color: #2d2926;
      span {
        em {
          position: relative;
          font-weight: bold;
          z-index: -1;
          &::before {
            content: '';
            display: block;
            width: 36px;
            height: 36px;
            background-color: #e2f0f6;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -17px;
            margin-top: -15px;
            border-radius: 100%;
            z-index: -1;
          }
        }
        &::after {
          background-color: #1cabe2;
        }
      }
    }
    .CalendarDay__highlighted_calendar {
      background-color: transparent;
      color: #2d2926;
      span {
        &::after {
          background-color: #1cabe2;
        }
      }
    }
    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_calendar,
    .CalendarDay__blocked_calendar:active,
    .CalendarDay__blocked_calendar:hover {
      color: #b7b8ba;
      span {
        &::after {
          background-color: #b7b8ba;
        }
      }
    }
  }
  .CalendarMonthGrid__horizontal {
    left: 0;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
  }
  .CalendarDay__outside,
  .CalendarDay__firstDayOfWeek,
  .CalendarDay__lastDayOfWeek {
    color: #b5b5b5;
  }
`;

export interface VillageStep1Data {
  userSelectDate: string;
  userTimeType: string;
  startTime: string;
  endTime: string;
}

interface VillageStep1Props {
  onSubmit: (data: VillageStep1Data) => void;
  queryParams?: any;
}

const VillageStep1: FC<PageProps | VillageStep1Props> = ({
  onSubmit,
  queryParams,
}) => {
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<VillageStep1Data>({
    defaultValues: {
      userSelectDate: '',
      userTimeType: '',
      startTime: '',
      endTime: '',
    },
  });

  const userSelectDate = watch('userSelectDate');
  const userTimeType = watch('userTimeType');

  const onSubmitForm: SubmitHandler<VillageStep1Data> = useCallback(
    (formData) => {
      onSubmit(formData);
    },
    [onSubmit],
  );

  moment.locale('ko');
  const [date, setDate] = useState(null);
  const [focused, setFocused] = useState(false);
  const notAvailable = '선택하신 날짜는 신청이 불가합니다.';
  const [applyData, setApplyData] = useState([]); // apply 데이터
  const [endData, setEndData] = useState([]); // noApply 데이터
  const [timetableData, setTimetableData] = useState([]); // 시간표 출력
  const [month, setMonth] = useState(0);

  const startOfMonth = moment()
    .add(month, 'M')
    .clone()
    .startOf('month')
    .format('YYYY-MM-DD'); // 이번달 첫 날
  const endOfMonth = moment()
    .add(month, 'M')
    .clone()
    .endOf('month')
    .format('YYYY-MM-DD'); // 이번달 마지막 날

  const isSameDay = (a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return (
      a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
    );
  };

  const loadDate = useCallback(() => {
    ChildParticipationControllerService.getApplyScheduleAllListUsingGet({
      startDate: startOfMonth,
      endDate: endOfMonth,
    })
      .then(({ data }) => {
        const apply = data.filter((item) => item.status === '신청');
        const noApply = data.filter((item) => item.status !== '신청');
        const timetable = data.filter(
          (item) => item.status === '신청' || item.status === '마감',
        );
        setApplyData(apply);
        setEndData(noApply);
        setTimetableData(timetable);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [endOfMonth, startOfMonth]);

  useEffect(() => {
    loadDate();
  }, [loadDate]);

  // 신청 가능일 moment 변환
  const availableMoment = applyData?.map((day) => moment(day.scheduleDate));

  // 신청 불가능일 moment 변환
  const endMoment = endData?.map((day) => moment(day.scheduleDate));

  // 신청 불가능일 블럭처리
  const isDayBlocked = (day1) =>
    endMoment?.some((day2) => isSameDay(day1, day2));

  // 신청 가능일 하이라이트
  const isDayHighlight = (day1) =>
    availableMoment?.some((day2) => isSameDay(day1, day2));

  const dateLabelString = date && date.format('YYYY년 MM월 DD일 dd요일');
  const dateFormat = date && date.format('YYYY-MM-DD');

  const [userDate, setUserDate] = useState('');
  const [userTime, setUserTime] = useState('');

  // 오늘 이전일 포함 3일 이후 날짜 설정
  const minDate = moment().add(3, 'days');

  const eachAvailable = useMemo(
    () =>
      applyData?.filter((available) =>
        available.scheduleDate.includes(String(dateFormat)),
      ),
    [date, applyData],
  );

  const eachTimeTables = useMemo(() => {
    let timetable = timetableData?.filter((timetable) =>
      timetable.scheduleDate.includes(String(dateFormat)),
    );
    timetable = timetable.sort(function (a, b) {
      if (a.startTime > b.startTime) {
        return 1;
      }
      if (a.startTime < b.startTime) {
        return -1;
      }
      return 0;
    });
    return timetable;
  }, [date, timetableData]);

  useMemo(() => setUserDate(eachAvailable[0]?.scheduleDate), [eachAvailable]);
  useMemo(() => setUserTime(eachAvailable[0]?.timeType), [eachAvailable]);

  const timeFormat = (time: string) => {
    return moment(time, 'HHmm').format('HH:mm');
  };

  return (
    <StepContainer>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DayPickerWrap>
          <DayPickerSingleDateController
            date={date}
            noBorder
            daySize={76}
            onDateChange={(selectDate: any) => {
              setDate(selectDate);
              setValue('userTimeType', '');
              setValue('startTime', '');
              setValue('endTime', '');
            }}
            focused={focused}
            onFocusChange={({ focused }) => {
              setFocused(focused);
            }}
            // isDayBlocked={isDayBlocked}
            isDayHighlighted={isDayHighlight}
            numberOfMonths={1}
            transitionDuration={0}
            onNextMonthClick={() => setMonth(month + 1)}
            onPrevMonthClick={() => setMonth(month - 1)}
            isOutsideRange={(date) => date.isBefore(minDate, 'day')}
            renderDayContents={(date) => (
              <div>
                <span>
                  <em>{date.format('D')}</em>
                </span>
              </div>
            )}
          />
        </DayPickerWrap>
        <SelectedContainer>
          <Tit size="s4" className="date">
            {eachAvailable.length === 0
              ? date
                ? notAvailable
                : '신청할 날짜를 선택해 주세요'
              : dateLabelString}
          </Tit>
          {eachAvailable?.length === 0 ? (
            <p>{date && notAvailable}</p>
          ) : (
            <>
              <input
                type="hidden"
                name="userSelectDate"
                value={userDate}
                ref={register({
                  required: {
                    value: true,
                    message: '시간을 선택해주세요',
                  },
                })}
              />
              <input
                type="hidden"
                name="startTime"
                ref={register({
                  required: {
                    value: true,
                    message: '시작 시간을 선택해주세요',
                  },
                })}
              />
              <input
                type="hidden"
                name="endTime"
                ref={register({
                  required: {
                    value: true,
                    message: '종료 시간을 선택해주세요',
                  },
                })}
              />
              <ul className="time-flex">
                {eachTimeTables.map((slot, index) => {
                  return (
                    <li key={index}>
                      <InputRadio
                        name="userTimeType"
                        ref={register({ required: true })}
                        tab
                        value={slot.timeType}
                        label={`${timeFormat(slot.startTime)} ~ `}
                        disabled={slot.status !== '신청'}
                        onClick={() => {
                          setValue('startTime', slot.startTime);
                          setValue('endTime', slot.endTime);
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
              <p className="tip">회차별 120분 프로그램으로 진행합니다.</p>
              <p className="tip">
                유아(5세-7세)를 위한 프로그램은 90분 동안 진행합니다.
              </p>
            </>
          )}
        </SelectedContainer>
        {errors.userTimeType && (
          <ErrorMessage>시간을 선택해주세요.</ErrorMessage>
        )}
        {/* {errors.startTime && (
          <ErrorMessage>시작 시간을 선택해주세요.</ErrorMessage>
        )}
        {errors.endTime && (
          <ErrorMessage>종료 시간을 선택해주세요.</ErrorMessage>
        )} */}
        <div className="btn-wrap">
          {userDate !== undefined ? (
            <Button size="sm" type="submit">
              정보 입력
            </Button>
          ) : null}
        </div>
      </form>
    </StepContainer>
  );
};
export default VillageStep1;
